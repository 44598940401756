import React, { useEffect, useRef, useState } from 'react'
import { Grid, Autocomplete, TextField, Button, Box, Checkbox } from '@mui/material'
import { useService } from 'components/route/ServiceRoute'
import { RNDespachanteGet } from 'services/services-mia/RNDespachanteGet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNBeneficiarioGet } from 'services/services-mia/RNBeneficiarioGet';
import { RNESArticuloGet } from 'services/services-mia/RNESArticuloGet';
import { Form, Formik, useFormik } from 'formik';
import * as yup from "yup";
import { FormikSelectInput, FormikTextInput, FormikDateInput } from "components/UI/Inputs/FormikInputs";
import * as XLSX from 'xlsx';
import { RNESInformeGet } from 'services/services-mia/RNESInformeGet';
import moment from 'moment';
import styles from "styles/pages/ModalActions.module.scss";
import { RNPSMerchantGet } from 'services/services-mia/RNPSMerchantGet';
import { RNPSMovimientoGet } from 'services/services-mia/RNPSMovimientosGet';

const validationSchema = yup.object({
    despachante: yup.string('Debe seleccionar uno').nullable(),
    beneficiario: yup.number('Debe ingresar un documento').nullable().typeError("Debe ingresar un documento"),
    fechadesde: yup.date()
        .required('Requerido')
        .when('fechahasta', (fechahasta, schema) =>
            schema.test({
                name: 'within-two-months',
                test: (fechadesde) => {
                    if (!fechadesde || !fechahasta) return true;
                    const maxDate = new Date(fechahasta);
                    maxDate.setMonth(maxDate.getMonth() - 2);
                    return fechadesde >= maxDate && fechadesde <= new Date();
                },
                message: 'La fecha desde no puede ser anterior a dos meses.',
            }).test({
                name: 'before-or-equal-to-fechahasta',
                test: (fechadesde) => {
                    if (!fechadesde || !fechahasta) return true;
                    return fechadesde <= new Date(fechahasta);
                },
                message: 'La fecha desde no puede ser posterior a la fecha hasta.',
            })
        ),
    fechahasta: yup.date()
        .required('Requerido')
        .max(new Date(), 'La fecha hasta no puede ser posterior a hoy'),
});


function InformesProgramaSocial() {
    const { service } = useService();
    const credentials = useRecoilValue(userCredentials);
    const setSnackBar = useSetRecoilState(snackbarData);
    const setRoute = useSetRecoilState(routeInfo);
    const [despachantes, setDespachantes] = useState([]);
    const [checked, setChecked] = useState(true);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [excel, setExcel] = useState([]);
    const formikRef = useRef();


    useEffect(() => {
        getDespachantes()
        setRoute({
            wordKey: `Informes - ${service.servicioNombre}`
        })
    }, [])


    // const formik = useFormik({
    //     initialValues: {
    //         despachante: { label: "Todos", value: null },
    //         beneficiario: { label: "Todos", value: null },
    //         articulo: { label: "Todos", value: null },
    //         fechadesde: moment().format('YYYY-MM-DD'),
    //         fechahasta: moment().format('YYYY-MM-DD')

    //     },
    //     validationSchema: validationSchema,
    //     onSubmit: async (values) => {
    //         const excel = await generateExcel(values);
    //         exportToExcel(excel)
    //     },
    // });


    const generateExcel = async (values) => {
        const data = {
            rnpsMerchantId: values.despachante,
            usuarioNroDeDocumento: values.beneficiario,
            fechaDesde: values.fechadesde,
            fechaHasta: values.fechahasta,
        }
        const response = await RNPSMovimientoGet(credentials, data);
        if (response.length === 0) {
            setSnackBar({ message: "No hay movimientos disponibles para los parámetros seleccionados", severity: "warning", open: true });
            return
        } else {
            const mapResponse = response.map((e) => {
                return {
                    "Documento": e.documento,
                    "Genero": e.genero === "M" ? "Masculino" : e.genero === "F" ? "Femenino" : "No binario",
                    "Apellido": e.apellido,
                    "Nombres": e.nombres,
                    "Fecha": e.fecha ? moment(e.fecha).format('DD/MM/YYYY') : "",
                    "Concepto": e.concepto,
                    "Monto": e.monto,
                    "Contraparte": e.contraparte,
                    "Programa": e.programa
                }
            })
            return mapResponse;
        }
    }

    const getDespachantes = async (page, pageSize) => {
        const data = {
            servicioHooliCod: service.servicioCod,
            merchantCUIT: null,
            merchantPFPJ: null,
            merchantStatusOperativo: null,
            merchantStatusRegistro: null,
        }
        const response = await RNPSMerchantGet(credentials, data);
        console.log(response);
        const newRows = response ? response?.map((usuario) => {
            return {
                id: usuario.merchantId,
                cuit: usuario.merchantCUIT,
                RZ: usuario.merchantRZ,
                localidad: usuario.localidadNombre,
                fisicaJuridicaCod: usuario.merchantPFPJ,
                fisicaJuridica: usuario.merchantPFPJLeyenda,
                statusOperativoCod: usuario.merchantStatusOperativo,
                statusOperativo: usuario.merchantStatusOperativoLeyenda,
                statusRegistro: usuario.merchantStatusRegistro,
                fechaAlta: usuario.merchantFechaAlta,
                merchantNombre: usuario.merchantNombre,
            };
        }) : [];
        setDespachantes([{ RZ: "Todos", id: "" }, ...newRows]);
    }

    const exportToExcel = (arr) => {
        const worksheet = XLSX.utils.json_to_sheet(arr);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `informe-${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
    };

    const handleChange = () => {
        setChecked(prev => !prev);
        formikRef.current.setFieldValue("beneficiario", "");
    }
    const handleSubmit = async () => {
        const excel = await generateExcel(formikRef.current.values);
        console.log(excel)
        if (excel) {
            exportToExcel(excel)
        }
    }
    // console.log(formikRef.current.values);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                despachante: null,
                beneficiario: null,
                fechadesde: moment().format('YYYY-MM-DD'),
                fechahasta: moment().format('YYYY-MM-DD')
            }}
            validationSchema={validationSchema}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column"]}>
                    <>
                        <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="despachante"
                                labelText="Comercio"
                            >
                                {despachantes.map(({ id, RZ }) => (
                                    <option
                                        key={id}
                                        data-id={id}
                                        value={id}
                                    >
                                        {RZ}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Grid>
                        <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                            <Box display={"flex"} gap={4}>
                                <FormikTextInput
                                    fullWidth={true}
                                    name="beneficiario"
                                    labelText="Beneficiario"
                                    disabled={checked}
                                />
                                <div>
                                    <label style={{ opacity: "0.6" }}>Todos</label>
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        color="success"
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                            <Box display={"flex"} gap={4}>
                                <FormikDateInput
                                    name={"fechadesde"}
                                    formik={formikRef}
                                    data={{ fechadesde: { label: "Fecha desde", placeholder: "desde" } }}
                                />
                                <FormikDateInput
                                    name={"fechahasta"}
                                    formik={formikRef}
                                    data={{ fechahasta: { label: "Fecha hasta", placeholder: "desde" } }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                            <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                                Generar y descargar excel
                            </Button>
                        </Grid>
                    </>
                </div>
            </Form>
        </Formik>
    )
}

export default InformesProgramaSocial
