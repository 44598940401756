import axiosPost from "./axiosPost";

export const RNPSMovimientoGet = async (credentials, data) => {
    const {
        rnpsMerchantId,
        usuarioNroDeDocumento,
        fechaDesde,
        fechaHasta,
    } = data;
    const body = {
        service: "RNPSMovimientoGet",
        params: {
            rnpsMerchantId,
            usuarioNroDeDocumento,
            fechaDesde,
            fechaHasta,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
