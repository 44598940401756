import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import Modal from "components/UI/Modal/Modal";
import { ResumenModal } from "./Actions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { HeaderButton } from "components";
import Cached from "@mui/icons-material/Cached";
import TimelineIcon from '@mui/icons-material/Timeline';
import { Buttons } from "components";
import Filters from "components/Filters";
import { ACTIONS } from "consts/Actions";
import moment from "moment";
import { comerciosNavidad as titles } from "consts/titulos-de-tablas";
import styles from "styles/pages/Pages.module.scss";
import { useService } from "components/route/ServiceRoute";
import { connectionRolGet, rolGet } from "services/services-mia";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { RNPSMerchantGet } from "services/services-mia/RNPSMerchantGet";
import XGrid from "components/UI/Grid/XGrid";
import * as yup from "yup";

const persona = [
    {
        personaCod: 'F',
        personaNombre: 'Física'
    },
    {
        personaCod: 'J',
        personaNombre: 'Jurídica'
    }
];
const registro = [
    {
        registroCod: 'OK',
        registroNombre: 'Ok'
    },
    {
        registroCod: 'NOOK',
        registroNombre: 'No ok'
    }
];
const estado = [
    {
        estadoCod: 'O',
        estadoNombre: 'Operativo'
    },
    {
        estadoCod: 'S',
        estadoNombre: 'Suspendido'
    },
    {
        estadoCod: "B",
        estadoNombre: 'Baja'
    },
    {
        estadoCod: 'F',
        estadoNombre: 'Backoffice'
    },
];

export default function ComerciosNavidad() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [loading, setLoading] = useState(false);
    const [comercios, setComercios] = useState([]);
    const { service } = useService();
    const validationSchema = yup.object({
        merchantCUIT: yup.string().matches(/^\d{2}-\d{8}-\d$/, "Debes ingresar un CUIT valido").nullable(),
    });
    const formik = useFormik({
        initialValues: {
            merchantCUIT: null,
            merchantPFPJ: null,
            merchantStatusOperativo: null,
            merchantStatusRegistro: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            getComercios(values)
        },
    })
    const resetForm = () => {
        formik.setFieldValue("merchantCUIT", "");
        formik.setFieldValue("merchantPFPJ", "");
        formik.setFieldValue("merchantStatusOperativo", "");
        formik.setFieldValue("merchantStatusRegistro", "");
        getComercios({});
    };

    const getComercios = async (values) => {
        setLoading(true);
        const data = {
            servicioHooliCod: service.servicioCod,
            merchantCUIT: values.merchantCUIT || null,
            merchantPFPJ: values.merchantPFPJ || null,
            merchantStatusOperativo: values.merchantStatusOperativo || null,
            merchantStatusRegistro: values.merchantStatusRegistro || null,
        }
        const response = await RNPSMerchantGet(credentials, data);
        console.log(response);
        const newRows = response ? response?.map((usuario) => {
            return {
                id: usuario.merchantId,
                cuit: usuario.merchantCUIT,
                RZ: usuario.merchantRZ,
                localidad: usuario.localidadNombre,
                fisicaJuridicaCod: usuario.merchantPFPJ,
                fisicaJuridica: usuario.merchantPFPJLeyenda,
                statusOperativoCod: usuario.merchantStatusOperativo,
                statusOperativo: usuario.merchantStatusOperativoLeyenda,
                statusRegistro: usuario.merchantStatusRegistro,
                fechaAlta: usuario.merchantFechaAlta,
                merchantNombre: usuario.merchantNombre,
            };
        }) : [];
        setComercios(newRows);
        setLoading(false);
    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
        getComercios(formik.values);
    };
    const updateList = useCallback(() => {
        setRowSelected({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };



    useEffect(() => {
        setRoute({
            wordKey: `Comercios - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])

    useEffect(() => {
        getComercios({});
    }, [])

    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} close={modalDetails.procede === ACTIONS.EXPORT ? true : false}>
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <ResumenModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "text", name: "merchantCUIT", label: "CUIT", disabled: false },
                                { type: "select", name: "merchantPFPJ", label: "Física/Juridica", disabled: false, options: persona, firtsOptionKey: "personaCod", firstOptionName: "personaNombre", firstOptionValue: "personaCod" },
                                { type: "select", name: "merchantStatusOperativo", label: "Estado operativo", disabled: false, options: estado, firtsOptionKey: "estadoCod", firstOptionName: "estadoNombre", firstOptionValue: "estadoCod" },
                                { type: "select", name: "merchantStatusRegistro", label: "Estado de registro", disabled: false, options: registro, firtsOptionKey: "registroCod", firstOptionName: "registroNombre", firstOptionValue: "registroCod" },
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => getComercios({})}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver movimientos"}
                        handleClick={handleClick}
                        data={{
                            title: `Comercios - Resumen: ${rowSelected.row.RZ}`,
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <TimelineIcon />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={styles.container}>
                <XGrid
                    rows={comercios}
                    titles={titles}
                    loading={loading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}